import { useState, useEffect } from 'react';

import Select from 'react-select';

import { useModal } from '@shared/components/Modal';
import type { TTableColumnsVisibility } from '@shared/components/Table';
import { ITableContextProps } from '@shared/components/Table';
import { getTypedObjectKeys } from '@shared/types/typedObjectEntries';

interface IOption<T, K extends keyof T> {
  value: K;
  label: string;
}

interface IProps {
  storageKey: string;
}

export const TableColumnsVisibility = <T,>({ storageKey }: IProps) => {
  const { additionalContext } = useModal<ITableContextProps<T>>();

  const { visibleColumns, setVisibleColumns, columnTitles } = additionalContext;
  const prepareOptions = () =>
    getTypedObjectKeys(columnTitles).map(column => ({
      value: column,
      label: columnTitles[column],
    }));

  const [options, setOptions] = useState<IOption<T, keyof T>[]>(prepareOptions);
  const [selectedOption, setSelectedOption] = useState<IOption<T, keyof T>[]>([]);

  useEffect(() => {
    const newOptions = prepareOptions();
    setOptions(newOptions);
    const newSelectedOptions = newOptions.filter(option => visibleColumns[option.value]);
    setSelectedOption(newSelectedOptions);
  }, [columnTitles, visibleColumns]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (selectedOptions: ReadonlyArray<IOption<T, keyof T>>) => {
    const updatedColumns: TTableColumnsVisibility<T> = options.reduce(
      (acc, option) => ({
        ...acc,
        [option.value]: selectedOptions.some(
          selectedOption => selectedOption.value === option.value,
        ),
      }),
      {} as TTableColumnsVisibility<T>,
    );

    setVisibleColumns(updatedColumns);
    localStorage.setItem(storageKey, JSON.stringify(updatedColumns));
    setSelectedOption([...selectedOptions]);
  };

  return (
    <div className='table-columns-visibility'>
      <h4>Columns visibility:</h4>
      <Select
        isMulti
        name='columns-visibility'
        options={options}
        className='basic-multi-select'
        classNamePrefix='select'
        placeholder='Select visible columns'
        onChange={handleChange}
        value={selectedOption}
        isClearable={false}
        getOptionValue={option => String(option.value)}
        getOptionLabel={option => option.label}
      />
    </div>
  );
};
