import { useState, useEffect, useCallback } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { getTypedObjectEntries } from '@shared/types/typedObjectEntries';

const initialPage = '1';
const initialSize = '50';

export const updateParams = <T extends object>(
  filters: URLSearchParams,
  updates: T,
  page?: string,
  size?: string,
) => {
  const newParams = new URLSearchParams(filters);

  page && newParams.set('page', page);
  size && newParams.set('size', size);

  getTypedObjectEntries(updates).forEach(([key, value]) => {
    const stringKey = String(key);
    const stringValue = String(value);

    const isMultipleKeys = stringKey.match(/^(.+)\[\d+\]$/);

    if (!value) {
      newParams.delete(stringKey);
      return;
    }

    // handle multiple keys
    if (isMultipleKeys) {
      const clearKey = stringKey.replace(/\[\d+\]$/, '');

      if (newParams.has(clearKey) && newParams.getAll(clearKey).includes(stringValue)) {
        newParams.delete(clearKey);
      }

      newParams.append(clearKey, stringValue);

      return;
    }

    newParams.set(stringKey, stringValue);
  });

  return newParams;
};

interface IProps {
  hasPagination?: boolean;
  disableUrlFilter?: boolean;
}

export const useFilter = <T extends object>({
  hasPagination = true,
  disableUrlFilter = false,
}: IProps = {}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams({
    page: initialPage,
    size: initialSize,
  });

  const [page, setPage] = useState(disableUrlFilter ? initialPage : searchParams.get('page'));
  const [size, setSize] = useState(disableUrlFilter ? initialSize : searchParams.get('size'));

  const initialFilter = hasPagination ? { page, size } : {};
  const [currentFilter, setCurrentFilter] = useState(
    new URLSearchParams(!disableUrlFilter ? searchParams.toString() : initialFilter),
  );

  useEffect(() => {
    if (hasPagination) {
      const currentPage = currentFilter.get('page') || initialPage;
      const currentSize = currentFilter.get('size') || initialSize;
      if (page === currentPage && size === currentSize) return;
      setCurrentFilter(prev => updateParams(prev, {} as T, page, size));
    }
  }, [page, size]); // eslint-disable-line react-hooks/exhaustive-deps

  // update URL
  useEffect(() => {
    if (disableUrlFilter) return;

    navigate({ search: currentFilter.toString() });
  }, [currentFilter, disableUrlFilter, navigate]);

  const updateFilter = useCallback(
    (updates: T) => {
      setCurrentFilter(prev => updateParams<T>(prev, updates));
    },
    [page, size], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const resetFilter = useCallback(() => {
    hasPagination && setPage(initialPage);
    hasPagination && setSize(initialSize);

    setCurrentFilter(new URLSearchParams(initialFilter));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const deleteFilterKey = useCallback((key: string) => {
    setCurrentFilter(prev => {
      const newParams = new URLSearchParams(prev);
      newParams.delete(key);
      return newParams;
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    currentFilter,
    updateFilter,
    resetFilter,
    deleteFilterKey,

    page,
    setPage,
    size,
    setSize,
  };
};
