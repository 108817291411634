export const ToggleIcon = ({ isOpen }: { isOpen: boolean }) => {
  return isOpen ? (
    <svg
      viewBox='0 0 15 15'
      fill='currentColor'
      style={{
        verticalAlign: 'top',
        color: 'rgb(88, 110, 117)',
        height: '1em',
        width: '1em',
        paddingLeft: '2px',
      }}
    >
      <path d='M0 5l6 6 6-6z'></path>
    </svg>
  ) : (
    <svg
      viewBox='0 0 15 15'
      fill='currentColor'
      style={{
        verticalAlign: 'top',
        color: 'rgb(42, 161, 152)',
        height: '1em',
        width: '1em',
        paddingLeft: '2px',
      }}
    >
      <path d='M0 14l6-6-6-6z'></path>
    </svg>
  );
};
