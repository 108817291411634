import { Badge, Card } from '@mui/material';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { ICollection } from './types';

type IProps = {
  collections: ICollection[];
  selectedCollection: ICollection | null;
  setSelectedCollection: (collection: ICollection) => void;
};

export const EntityCollectionSelector = ({
  collections,
  selectedCollection,
  setSelectedCollection,
}: IProps) => {
  return (
    <Card sx={{ width: '100%', maxWidth: 220 }}>
      <List component='nav' sx={{ py: 2 }}>
        {collections.map((collection, idx) => (
          <ListItemButton
            key={idx}
            disabled={!collection.size}
            selected={selectedCollection?.path === collection.path}
            onClick={() => setSelectedCollection(collection)}
          >
            <ListItemIcon sx={{ fontSize: 18, minWidth: '36px' }}>{collection.icon}</ListItemIcon>
            <ListItemText primary={collection.name} />
            <Badge showZero badgeContent={collection.size} sx={{ mr: 1 }} color='info' />
          </ListItemButton>
        ))}
      </List>
    </Card>
  );
};
