import RefreshBtn from '@features/refresh-btn';
import { TableControlMenu } from '@features/table-control-menu';
import { MenuItem } from '@mui/material';

import { OrdersDownloadBtn } from './DownloadBtn';
import { OrdersResendBtn } from './ResendBtn';

type TProps = {
  refetch: () => void;
};

export const BulkOrders = ({ refetch }: TProps) => {
  return (
    <TableControlMenu>
      <MenuItem>
        <RefreshBtn refetch={refetch} />
      </MenuItem>
      <MenuItem>
        <OrdersDownloadBtn />
      </MenuItem>
      <MenuItem>
        <OrdersResendBtn />
      </MenuItem>
    </TableControlMenu>
  );
};
