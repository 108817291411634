import { useState, useRef, useEffect } from 'react';

import get from 'lodash/get';

import { Box, Fade } from '@mui/material';

import { CardRenderer } from './CardRenderer';
import { EntityCollectionSelector } from './EntityCollectionSelector';
import { Toggle } from './Toggle';
import { ICollection, JsonSchema } from './types';

type IProps = {
  title: string;
  data: JsonSchema;
  promotedKeys?: string[];
  headerFn?: (entry: JsonSchema) => React.ReactNode;
  collections?: ICollection[];
  withCollectionSelector?: boolean;
};

const areAllCollectionsEmpty = (data: JsonSchema, collections: ICollection[]) => {
  return collections.every(c => get(data, c.path)?.length === 0);
};

const firstNonEmptyCollection = (data: JsonSchema, collections: ICollection[]) => {
  return collections.find(c => get(data, c.path)?.length > 0);
};

export const CardBlockRendered = ({
  title,
  data,
  promotedKeys,
  headerFn,
  collections = [],
  withCollectionSelector = true,
}: IProps) => {
  const [selectedCollection, setSelectedCollection] = useState<ICollection | undefined>(
    firstNonEmptyCollection(data, collections),
  );
  const [isOpen, setIsOpen] = useState(false);
  const titleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen && titleRef.current) {
      titleRef.current.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'nearest' });
    }
  }, [isOpen, selectedCollection]);

  return (
    <Box>
      <Toggle
        ref={titleRef}
        disabled={areAllCollectionsEmpty(data, collections)}
        title={title}
        isOpen={isOpen}
        handleClick={() => setIsOpen(prev => !prev)}
      />
      <Fade in={isOpen} unmountOnExit>
        <Box display='flex' flexDirection='row' alignItems='flex-start' gap={2}>
          {withCollectionSelector && (
            <EntityCollectionSelector
              collections={collections.map(c => ({ ...c, size: get(data, c.path)?.length }))}
              selectedCollection={selectedCollection}
              setSelectedCollection={setSelectedCollection}
            />
          )}
          {selectedCollection && (
            <CardRenderer
              promotedKeys={promotedKeys}
              data={get(data, selectedCollection.path)}
              headerFn={headerFn}
            />
          )}
        </Box>
      </Fade>
    </Box>
  );
};
