import { useBulkApproveRequestMutation } from '@entities/requests';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { useModal } from '@shared/components/Modal';
import { useTableContext } from '@shared/components/Table';

import { ConfirmApproveRejectRequest } from './ConfirmApproveRejectRequest';

type TProps = {
  refetch: () => void;
};

export function RequestsApproveBtn({ refetch }: TProps) {
  const { openModal } = useModal();
  const { selectedIds, setSelectedIds } = useTableContext();

  return (
    <button
      onClick={() =>
        openModal(
          <ConfirmApproveRejectRequest
            data={{ ids: selectedIds, reason: '' }}
            message='Do you want to approve ALL selected requests?'
            mutation={useBulkApproveRequestMutation}
            mutationErrorName='ids'
            refetch={refetch}
          />,
          'md',
          { selectedIds, setSelectedIds },
        )
      }
      className='requests-approve-btn'
    >
      <CheckCircle color='success' />
      Approve All
    </button>
  );
}
