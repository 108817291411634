import type { TRequestType, TRequestTableColumns } from '@entities/requests';
import { useRefetchAuto } from '@features/refetch-auto';
import THeadToggler from '@features/table-thead-toggler';
import ErrorMessage from '@shared/components/ErrorMessage';
import Loading from '@shared/components/Loading';
import LoadingSpinner from '@shared/components/LoadingSpinner';
import { Pagination, PaginationRows } from '@shared/components/Pagination';
import { TBody, THead, Table, TableContainer, TableProvider } from '@shared/components/Table';

import { getMutationHook } from './settings/getMutationHook';
import { useTableSettings } from './settings/useTableSettings';

interface IProps {
  type: TRequestType;
}

const RequestsWidget = ({ type }: IProps) => {
  const useMutationHook = getMutationHook(type);
  const { refreshInterval, setRefreshInterval } = useRefetchAuto({
    storageKey: `refetch-auto-${type}`,
  });

  const {
    requests,
    isLoading,
    isError,
    error,
    isFetching,
    refetch,

    filter,
  } = useMutationHook({ refreshInterval });

  const {
    columnTitles,
    hiddenColumnKeys,
    renderFilterElement,
    getTheadBefore,
    getTheadAfter,
    getTbodyBefore,
    getTbodyAfter,
    renderRow,
  } = useTableSettings({
    type,
    refetch,
    setRefreshInterval,
    refreshInterval,
  });

  if (isLoading) return <LoadingSpinner />;
  if (isError) return <ErrorMessage error={error} />;

  if (requests) {
    return (
      <>
        {isFetching && <Loading />}

        <TableProvider<TRequestTableColumns>
          columnTitles={columnTitles}
          hiddenColumnKeys={hiddenColumnKeys}
          storageKey={`request-${type}`}
          {...filter}
        >
          <THeadToggler />

          <TableContainer className='table-docked-controls'>
            <Table>
              <THead
                items={requests.data}
                renderItem={renderFilterElement}
                beforeColumns={getTheadBefore}
                afterColumns={getTheadAfter}
              />
              <TBody
                items={requests.data}
                renderItem={renderRow}
                beforeColumns={getTbodyBefore}
                afterColumns={getTbodyAfter}
              />
            </Table>
          </TableContainer>
          <div className='pagination-container'>
            <Pagination data={requests.meta} />
            <PaginationRows />
          </div>
        </TableProvider>
      </>
    );
  }

  return null;
};

export default RequestsWidget;
