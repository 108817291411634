import { useState } from 'react';

import { Box } from '@mui/material';

type IProps = {
  children: React.ReactNode;
  canCopyClipboard?: boolean;
};

export const Highlight = ({ children, canCopyClipboard = false }: IProps) => {
  const [copied, setCopied] = useState(false);

  const handleClick = () => {
    if (canCopyClipboard && typeof children === 'string') {
      navigator.clipboard.writeText(children);
      setCopied(true);
      setTimeout(() => setCopied(false), 1000);
    }
  };

  return (
    <Box
      component={'span'}
      onClick={handleClick}
      sx={{
        px: 1,
        backgroundColor: copied ? '#d1ffd1' : '#f4f3f3',
        py: 0.5,
        borderRadius: 1.5,
        fontSize: 14,
        cursor: canCopyClipboard ? 'pointer' : 'default',
        transition: 'background-color 0.3s ease',
        '&:hover':
          canCopyClipboard && !copied
            ? {
                backgroundColor: '#e8e8e8',
              }
            : {},
      }}
    >
      {children}
    </Box>
  );
};
