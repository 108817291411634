import { RootState } from '@app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ILoadingState {
  isLoading: boolean;
  type: 'default' | 'indeterminate';
}

const initialState: ILoadingState = {
  isLoading: false,
  type: 'default',
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    showLoading: (state, action: PayloadAction<'default' | 'indeterminate' | undefined>) => {
      state.isLoading = true;
      state.type = action.payload ?? 'default';
    },
    hideLoading: state => {
      state.isLoading = false;
      state.type = 'default';
    },
  },
});

export const actions = loadingSlice.actions;
export const { showLoading, hideLoading } = loadingSlice.actions;
export const selectLoading = (state: RootState) => state.loading;
export default loadingSlice.reducer;
