import { FC } from 'react';

import { RefetchAuto } from '@features/refetch-auto';
import RefreshBtn from '@features/refresh-btn';
import { TableControlMenu } from '@features/table-control-menu';
import { Divider, MenuItem } from '@mui/material';

import { RequestsApproveBtn } from './ApproveBtn';
import { RequestsRejectBtn } from './RejectBtn';

interface IProps {
  refetch: () => void;
  setRefreshInterval: (interval: string) => void;
  refreshInterval: string;
}

export const BulkRequests: FC<IProps> = ({ refetch, setRefreshInterval, refreshInterval }) => {
  return (
    <TableControlMenu>
      <MenuItem>
        <RefreshBtn refetch={refetch} />
      </MenuItem>
      <MenuItem data-menu-type='dropdown'>
        <RefetchAuto refreshInterval={refreshInterval} onChangeInterval={setRefreshInterval} />
      </MenuItem>
      <Divider />
      <MenuItem>
        <RequestsRejectBtn refetch={refetch} />
      </MenuItem>
      <MenuItem>
        <RequestsApproveBtn refetch={refetch} />
      </MenuItem>
    </TableControlMenu>
  );
};
