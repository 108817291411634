import { baseApi } from '@app/store/index.api';

import {
  IRequestApproveQuery,
  IRequestApproveResponse,
  IRequestRejectQuery,
  IRequestRejectResponse,
  IRequestBulkApproveQuery,
  IRequestBulkApproveResponse,
  IRequestBulkRejectQuery,
  IRequestBulkRejectResponse,
  IRequestInfoQuery,
  IRequestInfoResponse,
  IRequestRefundResponse,
  IRequestRefundQuery,
} from '../model/types';

const requestsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    approveRequest: builder.mutation<IRequestApproveResponse, IRequestApproveQuery>({
      query: ({ id, actual_amount, customer_transaction_id, reason }) => {
        return {
          url: `/requests/${id}/approve`,
          method: 'PATCH',
          body: { actual_amount, customer_transaction_id, reason },
        };
      },
    }),
    rejectRequest: builder.mutation<IRequestRejectResponse, IRequestRejectQuery>({
      query: ({ id, reason }) => {
        return {
          url: `/requests/${id}/reject`,
          method: 'PATCH',
          body: { reason },
        };
      },
    }),
    refundRequest: builder.mutation<IRequestRefundResponse, IRequestRefundQuery>({
      query: ({ id, reason }) => {
        return {
          url: `/requests/${id}/refund`,
          method: 'PATCH',
          body: { reason },
        };
      },
    }),
    bulkApproveRequest: builder.mutation<IRequestBulkApproveResponse, IRequestBulkApproveQuery>({
      query: ({ ids, reason }) => {
        return {
          url: `/requests/bulk-approve`,
          method: 'POST',
          body: { ids, reason },
        };
      },
    }),
    bulkRejectRequest: builder.mutation<IRequestBulkRejectResponse, IRequestBulkRejectQuery>({
      query: ({ ids, reason }) => {
        return {
          url: `/requests/bulk-reject`,
          method: 'POST',
          body: { ids, reason },
        };
      },
    }),
    viewRequest: builder.query<IRequestInfoResponse, IRequestInfoQuery>({
      query: ({ id }) => {
        return {
          url: `/requests/${id}/info`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useApproveRequestMutation,
  useRejectRequestMutation,
  useRefundRequestMutation,
  useBulkApproveRequestMutation,
  useBulkRejectRequestMutation,
  useViewRequestQuery,
} = requestsApi;
