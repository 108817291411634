import { useSelector } from 'react-redux';

import Loading from '@shared/components/Loading';

import { selectLoading } from '../model/slices/loadingSlice';

export const LoadingContainer = () => {
  const { isLoading, type } = useSelector(selectLoading);

  if (!isLoading) return null;
  return <Loading type={type} />;
};
