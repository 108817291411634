import { FC } from 'react';

import { ReactJsonViewProps } from '@microlink/react-json-view';
import { Box } from '@mui/material';

import { CardBlockRendered } from './CardBlockRendered';
import { JsonCard } from './JsonCard';
import { JsonSchema } from './types';
import { resolveDataPath } from './utils';
import { defaultViewConfig } from './ViewConfig';

interface IProps extends Partial<ReactJsonViewProps> {
  data: JsonSchema;
}

export const RequestPrettyView: FC<IProps> = ({ data }) => {
  return (
    <Box mt={2}>
      {defaultViewConfig.singleCards.map(cardConfig => (
        <Box key={cardConfig.key} mb={3}>
          <JsonCard
            jsonKey={cardConfig.key}
            data={resolveDataPath(data, cardConfig.dataPath)}
            promotedKeys={cardConfig.promotedKeys}
            headerRenderer={cardConfig.headerRenderer}
            unpackPromotedKeys={cardConfig.unpackPromotedKeys}
            promotedKeyAsRoot={cardConfig.promotedKeyAsRoot}
          />
        </Box>
      ))}

      {defaultViewConfig.blockCards.map(blockConfig => (
        <Box key={blockConfig.key} mb={3}>
          <CardBlockRendered
            title={blockConfig.title || ''}
            promotedKeys={blockConfig.promotedKeys}
            headerFn={blockConfig.headerRenderer}
            collections={blockConfig.collections}
            withCollectionSelector={blockConfig.withCollectionSelector}
            data={resolveDataPath(data, blockConfig.dataPath)}
          />
        </Box>
      ))}
    </Box>
  );
};
