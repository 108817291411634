import {
  useState,
  createContext,
  PropsWithChildren,
  useContext,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';

import { generateDefaultVisibleColumns } from '@features/table-columns-visibility';

import type {
  TTableColumnTitles,
  TTableColumnsVisibility,
  TTableColumnsFilter,
} from '../model/types';

export interface ITableContextProps<T> {
  selectedIds: string[];
  setSelectedIds: Dispatch<SetStateAction<string[]>>;
  toggleSelectedId: (id: string) => void;
  enabledIds: string[];
  setEnabledIds: Dispatch<SetStateAction<string[]>>;
  handleCheckAll: (e: React.ChangeEvent<HTMLInputElement>) => void;

  storageKey?: string;
  columnTitles: TTableColumnTitles<T>;
  visibleColumns: TTableColumnsVisibility<T>;
  setVisibleColumns: Dispatch<SetStateAction<TTableColumnsVisibility<T>>>;
  isTheadVisible: boolean;
  setIsTheadVisible: Dispatch<SetStateAction<boolean>>;

  currentFilter: URLSearchParams;
  updateFilter: (filters: TTableColumnsFilter<T>) => void;
  resetFilter: () => void;
  deleteFilterKey: (key: string) => void;

  page: string;
  setPage: Dispatch<SetStateAction<string>>;
  size: string;
  setSize: Dispatch<SetStateAction<string>>;
}

/* eslint-disable */
const TableContext = createContext<ITableContextProps<any>>({
  selectedIds: [],
  setSelectedIds: () => {},
  toggleSelectedId: () => {},
  enabledIds: [],
  setEnabledIds: () => {},
  handleCheckAll: () => {},

  storageKey: '',
  columnTitles: {},
  visibleColumns: {},
  setVisibleColumns: () => {},
  isTheadVisible: false,
  setIsTheadVisible: () => {},

  currentFilter: new URLSearchParams(),
  updateFilter: () => {},
  resetFilter: () => {},
  deleteFilterKey: () => {},

  page: '1',
  setPage: () => {},
  size: '15',
  setSize: () => {},
});
/* eslint-disable */

interface ITableProviderProps<T> {
  storageKey?: string;
  columnTitles: TTableColumnTitles<T>;
  hiddenColumnKeys?: (keyof TTableColumnTitles<T>)[];
  currentFilter: URLSearchParams;
  updateFilter: (filters: TTableColumnsFilter<T>) => void;
  resetFilter: () => void;
  deleteFilterKey: (key: string) => void;
  page: string;
  setPage: Dispatch<SetStateAction<string>>;
  size: string;
  setSize: Dispatch<SetStateAction<string>>;
}

const TableProvider = <T,>({
  children,
  storageKey = '',
  columnTitles,
  hiddenColumnKeys,

  currentFilter,
  updateFilter,
  resetFilter,
  deleteFilterKey,
  page,
  setPage,
  size,
  setSize,
}: PropsWithChildren<ITableProviderProps<T>>) => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [enabledIds, setEnabledIds] = useState<string[]>([]);
  const [isTheadVisible, setIsTheadVisible] = useState(false);

  const defaultVisibleColumns = generateDefaultVisibleColumns(columnTitles, hiddenColumnKeys);
  const [visibleColumns, setVisibleColumns] = useState(() => {
    const storedColumns = localStorage.getItem(storageKey);
    if (storedColumns) {
      return JSON.parse(storedColumns) as TTableColumnsVisibility<T>;
    }
    return defaultVisibleColumns;
  });

  useEffect(() => {
    if (!storageKey) return;
    const storedColumns = localStorage.getItem(storageKey);
    const visibleColumnsFromStorage = storedColumns ? JSON.parse(storedColumns) : visibleColumns;
    setVisibleColumns(prev => ({ ...prev, ...visibleColumnsFromStorage }));
  }, [storageKey]);

  const toggleSelectedId = (id: string) => {
    setSelectedIds(prevIds => {
      return prevIds.includes(id) ? prevIds.filter(item => item !== id) : [...prevIds, id];
    });
  };

  const handleCheckAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setSelectedIds(prevIds => [...new Set([...prevIds, ...enabledIds])]);
    } else {
      setSelectedIds(prevIds => prevIds.filter(id => !enabledIds.includes(id)));
    }
  };

  return (
    <TableContext.Provider
      value={{
        selectedIds,
        setSelectedIds,
        toggleSelectedId,
        enabledIds,
        setEnabledIds,
        handleCheckAll,

        columnTitles,
        visibleColumns,
        setVisibleColumns,
        isTheadVisible,
        setIsTheadVisible,

        currentFilter,
        updateFilter,
        resetFilter,
        deleteFilterKey,
        page,
        setPage,
        size,
        setSize,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};

const useTableContext = <T,>() => {
  const context = useContext<ITableContextProps<T>>(TableContext);

  return context;
};

export { TableProvider, useTableContext };
