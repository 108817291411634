import { FC } from 'react';

import { format } from 'date-fns';
import pick from 'lodash/pick';

import ReactJsonView from '@microlink/react-json-view';
import { Box, Card, Divider, CardContent, CardHeader, Typography } from '@mui/material';
import RequestStatusLabel from '@shared/components/RequestStatusLabel';

import { Highlight } from './Highlight';
import { JsonSchema } from './types';
import { getPromotedKeysDisplayName } from './utils';
import { commonJsonViewProps } from './ViewConfig';

interface JsonCardProps {
  data: JsonSchema;
  jsonKey: string;
  promotedKeys?: string[];
  headerRenderer: (item: JsonSchema) => React.ReactNode;
  withDefaultId?: boolean;
  unpackPromotedKeys?: boolean;
  promotedKeysRootName?: string | false;
  promotedKeyAsRoot?: string;
}

export const JsonCard: FC<JsonCardProps> = ({
  data,
  jsonKey,
  promotedKeys = [],
  headerRenderer,
  unpackPromotedKeys = false,
  withDefaultId = true,
  promotedKeysRootName = 'fast-access',
  promotedKeyAsRoot,
  ...props
}) => {
  if (!data) {
    return (
      <Card
        elevation={0}
        sx={{
          userSelect: 'none',
          cursor: 'not-allowed',
          bgcolor: 'action.disabled',
          opacity: 0.7,
        }}
      >
        <CardHeader
          title={
            <Typography>
              no data for <strong>{jsonKey}</strong>
            </Typography>
          }
        />
      </Card>
    );
  }

  const renderPromotedKeys = () => {
    const promotedData = pick(data, promotedKeys);
    if (unpackPromotedKeys) {
      return (
        <Box display='flex' flexDirection='column' gap={0}>
          {Object.entries(promotedData).map(([key, value]) => {
            const isStatus = key === 'status' && typeof value === 'string';
            let valueToRender: string | React.ReactNode = value?.toString() || 'N/A';
            if (isStatus) {
              valueToRender = <RequestStatusLabel status={value.toString()} />;
            }
            return (
              <Typography key={key} variant='body2'>
                {key}: {valueToRender}
              </Typography>
            );
          })}
        </Box>
      );
    }

    const sourceData = promotedKeyAsRoot
      ? promotedData[promotedKeyAsRoot as keyof JsonSchema] || promotedData
      : promotedData;
    const displayName = getPromotedKeysDisplayName(promotedKeyAsRoot, promotedKeysRootName);

    return (
      <ReactJsonView
        name={displayName}
        collapsed={0}
        src={sourceData as object}
        {...commonJsonViewProps}
        {...props}
      />
    );
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Card>
        <CardHeader
          sx={{ pb: 0 }}
          title={
            <Box display='flex' alignContent='center' flexDirection='row' gap={2}>
              {headerRenderer ? (
                headerRenderer(data)
              ) : (
                <Typography>
                  id: <Highlight>{data.id.toString()}</Highlight>
                </Typography>
              )}
            </Box>
          }
          action={
            <Box>
              created_at{' '}
              <strong>{format(new Date(data.created_at.toString()), 'do MMM, yyyy HH:mm')}</strong>
            </Box>
          }
        />
        <CardContent sx={{ '&:last-child': { pb: 2 } }}>
          {promotedKeys.length > 0 && (
            <>
              {renderPromotedKeys()}
              <Divider sx={{ my: 1.5 }} />
            </>
          )}
          <ReactJsonView name={'full-json'} {...commonJsonViewProps} collapsed={0} src={data} />
        </CardContent>
      </Card>
    </Box>
  );
};
