import Cancel from '@mui/icons-material/Cancel';

import { ConfirmApproveRejectRequest } from './ConfirmApproveRejectRequest';
import { useBulkRejectRequestMutation } from '../../../entities/requests';
import { useModal } from '../../../shared/components/Modal';
import { useTableContext } from '../../../shared/components/Table/context';

type TProps = {
  refetch: () => void;
};

export function RequestsRejectBtn({ refetch }: TProps) {
  const { openModal } = useModal();

  const { selectedIds, setSelectedIds } = useTableContext();
  return (
    <button
      onClick={() =>
        openModal(
          <ConfirmApproveRejectRequest
            data={{ ids: selectedIds, reason: '' }}
            message='Do you want to reject ALL selected requests?'
            mutation={useBulkRejectRequestMutation}
            mutationErrorName='ids'
            refetch={refetch}
          />,
          'md',
          { selectedIds, setSelectedIds },
        )
      }
      className='requests-reject-btn'
    >
      <Cancel color='error' />
      Reject All
    </button>
  );
}
