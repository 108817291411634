import { TRequestType } from '@entities/requests';
import { useLazyRequestDepositFilterQuery } from '@entities/requests/deposit';
import { useLazyRequestWithdrawalFilterQuery } from '@entities/requests/withdrawal';

export const getLazyFilterQuery = (type: TRequestType) => {
  switch (type) {
    case 'deposit':
      return useLazyRequestDepositFilterQuery;
    case 'withdrawal':
      return useLazyRequestWithdrawalFilterQuery;
  }
};
