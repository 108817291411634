import { FC } from 'react';

import { useHotkeys } from 'react-hotkeys-hook';

import RefreshIcon from '@mui/icons-material/Refresh';

interface IProps {
  refetch: () => void;
}

const RefreshBtn: FC<IProps> = ({ refetch }) => {
  useHotkeys('shift+r', () => {
    refetch();
  });

  return (
    <button className='refresh-btn' onClick={refetch}>
      <RefreshIcon />
      Refresh
    </button>
  );
};

export default RefreshBtn;
