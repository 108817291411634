import { useState, useCallback, Children } from 'react';

import debounce from 'lodash/debounce';
import Select, {
  MenuListProps,
  MultiValue,
  OptionProps,
  ValueContainerProps,
  components,
} from 'react-select';

import { ISelectMultiOption, reactSelectMultiStyles } from '@shared/components/Form';
import { TTableColumnsFilter, useTableContext } from '@shared/components/Table';
import { IBaseObject, TUseLazyQuery } from '@shared/types/fetch-data';

export const ValueContainer = ({ children, ...props }: ValueContainerProps<ISelectMultiOption>) => {
  const {
    getValue,
    hasValue,
    isMulti,
    selectProps: { inputValue },
  } = props;

  if (!hasValue || !isMulti) {
    return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
  }

  return (
    <components.ValueContainer {...props}>
      {!inputValue && <div className='placeholder'>{`${getValue().length} selected`}</div>}
      {Array.isArray(children) ? children[1] : children}
    </components.ValueContainer>
  );
};

export const Option = (props: OptionProps<ISelectMultiOption>) => {
  return (
    <components.Option {...props} className='react-select-option'>
      <>
        <input type='checkbox' checked={props.isSelected} onChange={() => null} />
        <label>{props.label}</label>
      </>
    </components.Option>
  );
};

export const MenuList = (props: MenuListProps<ISelectMultiOption, true>) => {
  if ('isLoading' in props && props.isLoading) {
    return <components.MenuList {...props} />;
  }

  const children = Children.toArray(props.children) as React.ReactElement[];
  const selectedOptions = children.filter(child => child.props.isSelected);
  const nonSelectedOptions = children.filter(child => !child.props.isSelected);
  const childrenToRender = [...selectedOptions, ...nonSelectedOptions];
  return <components.MenuList {...props}>{childrenToRender}</components.MenuList>;
};

const useAsyncOptions = <T extends IBaseObject>(
  getFilterOptions: TUseLazyQuery<string, T>,
  labelKey: keyof T,
  valueKey: keyof T,
  relationFilterKey: string,
) => {
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<ISelectMultiOption[]>([]);

  const fetchOptions = async (input: string) => {
    setIsLoading(true);
    try {
      const params = new URLSearchParams({
        'filter[column]': relationFilterKey,
        'filter[search]': input,
      });
      const response = await getFilterOptions(params.toString());
      setOptions(
        response.data.data.map(item => ({
          value: String(item[valueKey]),
          label: String(item[labelKey]),
        })),
      );
    } finally {
      setIsLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedAsyncOptions = useCallback(
    debounce(
      (input: string) => {
        fetchOptions(input);
      },
      300,
      { leading: true },
    ),
    [relationFilterKey, getFilterOptions, valueKey, labelKey],
  );

  const handleInputChange = (input: string) => {
    setInputValue(input);
    if (input === '') {
      return;
    }
    debouncedAsyncOptions(input);
  };

  return {
    inputValue,
    isLoading,
    handleInputChange,
    getAsyncOptions: debouncedAsyncOptions,
    options,
  };
};

interface IProps<T extends IBaseObject> {
  /**
   * `relationFilterKey` is used in the `{collection}/filter` endpoint and can be different from the `name`.
   * Results obtained from the `{collection}/filter`  endpoint will be used to build the options list.
   * Upon selecting an option from the list, the `name` parameter will be used in the main query of the collection.
   */
  relationFilterKey: string;

  /**
   * `name` is used in the main query of the collection.
   */
  name: string;
  labelKey: keyof T;
  valueKey: keyof T;
  getFilterOptions: TUseLazyQuery<string, T>;
}

export const FilterSelectAsync = <T extends IBaseObject>({
  relationFilterKey,
  labelKey,
  valueKey,
  name,
  getFilterOptions,
}: IProps<T>) => {
  const { currentFilter, updateFilter, deleteFilterKey } = useTableContext<T>();
  const filterKey = `filter[${String(name)}][]`;

  const { inputValue, handleInputChange, options, getAsyncOptions, isLoading } = useAsyncOptions(
    getFilterOptions,
    labelKey,
    valueKey,
    relationFilterKey,
  );

  const [selectedOptions, setSelectedOptions] = useState<MultiValue<ISelectMultiOption>>(
    currentFilter.getAll(filterKey).map((value: string) => ({
      value,
      label: value,
    })),
  );

  const handleChange = (selectedOptions: MultiValue<ISelectMultiOption> | null) => {
    setSelectedOptions(selectedOptions as ISelectMultiOption[]);

    if (selectedOptions.length === 0) {
      deleteFilterKey(filterKey);
      return;
    }

    const newFilter = {} as Record<string, string>;
    selectedOptions.forEach((option, index) => {
      newFilter[`${filterKey}[${index}]`] = option.value;
    });
    updateFilter(newFilter as TTableColumnsFilter<T>);
  };

  const handleMenuOpen = () => {
    getAsyncOptions('');
  };

  return (
    <Select
      className='table-filter-select-async'
      components={{
        DropdownIndicator: null,
        IndicatorSeparator: null,
        MenuList,
        Option,
        ValueContainer,
      }}
      inputValue={inputValue}
      isLoading={isLoading}
      isMulti
      isClearable
      hideSelectedOptions={false}
      maxMenuHeight={200}
      menuShouldBlockScroll={true}
      menuShouldScrollIntoView={false}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onMenuOpen={handleMenuOpen}
      options={options}
      styles={reactSelectMultiStyles}
      value={selectedOptions}
      closeMenuOnSelect={false}
    />
  );
};
