import get from 'lodash/get';

import { JsonSchema } from './types';

export const resolveDataPath = (data: JsonSchema, path: string): JsonSchema => {
  if (path === 'root' || path === '.') return data;
  return get(data, path);
};

export const getPromotedKeysDisplayName = (
  promotedKeyAsRoot: string,
  promotedKeysRootName: string | false,
) => {
  if (!promotedKeysRootName) return promotedKeyAsRoot;
  return promotedKeyAsRoot || promotedKeysRootName;
};
