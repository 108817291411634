import type { TTransactionType, TTransactionTableColumns } from '@entities/transactions';
import THeadToggler from '@features/table-thead-toggler';
import ErrorMessage from '@shared/components/ErrorMessage';
import Loading from '@shared/components/Loading';
import LoadingSpinner from '@shared/components/LoadingSpinner';
import { Pagination, PaginationRows } from '@shared/components/Pagination';
import { TBody, THead, Table, TableContainer, TableProvider } from '@shared/components/Table';

import { getMutationHook } from './settings/getMutationHook';
import { useTableSettings } from './settings/useTableSettings';

interface IProps {
  type: TTransactionType;
}

const TransactionsWidget = ({ type }: IProps) => {
  const useMutationHook = getMutationHook(type);

  const {
    transactions,
    isLoading,
    isError,
    error,
    isFetching,
    refetch,

    filter,
  } = useMutationHook();

  const { columnTitles, renderFilterElement, getTheadAfter, renderRow, getTbodyAfter } =
    useTableSettings({
      type,
      refetch,
    });

  if (isLoading) return <LoadingSpinner />;
  if (isError) return <ErrorMessage error={error} />;

  if (transactions) {
    return (
      <>
        {isFetching && <Loading />}

        <TableProvider<TTransactionTableColumns>
          columnTitles={columnTitles}
          storageKey={`transaction-${type}`}
          {...filter}
        >
          <THeadToggler />

          <TableContainer className='table-docked-controls'>
            <Table>
              <THead
                items={transactions.data}
                renderItem={renderFilterElement}
                afterColumns={getTheadAfter}
              />
              <TBody
                items={transactions.data}
                renderItem={renderRow}
                afterColumns={getTbodyAfter}
              />
            </Table>
          </TableContainer>
          <div className='pagination-container'>
            <Pagination data={transactions.meta} />
            <PaginationRows />
          </div>
        </TableProvider>
      </>
    );
  }

  return null;
};

export default TransactionsWidget;
