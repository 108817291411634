import { FC } from 'react';

import { TModalSize, useModal } from '@shared/components/Modal';

interface IProps {
  text: string;
  icon?: JSX.Element;
  modalContent: JSX.Element;
  size?: TModalSize;
}

const ModalOpenBtn: FC<IProps> = ({ text, icon, modalContent, size }) => {
  const { openModal } = useModal();

  return (
    <button onClick={() => openModal(modalContent, size)}>
      {icon}
      {text}
    </button>
  );
};

export default ModalOpenBtn;
