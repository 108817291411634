import React, {
  Children,
  cloneElement,
  FC,
  isValidElement,
  PropsWithChildren,
  useState,
} from 'react';

import TableChartIcon from '@mui/icons-material/TableChart';
import { Menu, MenuItem, Tooltip } from '@mui/material';

import './table-control-menu.css';

type ITableControlMenu = PropsWithChildren;

export const TableControlMenu: FC<ITableControlMenu> = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title='Table actions'>
        <button type='button' onClick={handleClick} className='table-control-menu-icon-button'>
          <TableChartIcon />
        </button>
      </Tooltip>
      <Menu
        id='table-control-menu'
        onSelect={handleClose}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        elevation={1}
        onClose={handleClose}
        // Keep mounted for hotkeys to work inside of the children components
        keepMounted
      >
        {Children.map(children, child => {
          if (
            isValidElement<
              React.ComponentProps<typeof MenuItem> & {
                'data-menu-type'?: string;
              }
            >(child)
          ) {
            return cloneElement(child, {
              onClick: (e: React.MouseEvent<HTMLLIElement>) => {
                if (child.props['data-menu-type'] !== 'dropdown') {
                  handleClose();
                }
                if (child.props.onClick) {
                  child.props.onClick(e);
                }
              },
            });
          }
          return child;
        })}
      </Menu>
    </>
  );
};
