import { FC } from 'react';

import Logo from '@features/logo';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

import { Nav } from '../Nav';

import './sidebar.css';

interface IProps {
  isWebSidebarOpen: boolean;
  setWebIsSidebarOpen: (value: boolean) => void;
  isMobSidebarOpen: boolean;
  setMobIsSidebarOpen: (value: boolean) => void;
}

const Sidebar: FC<IProps> = ({
  isWebSidebarOpen,
  setWebIsSidebarOpen,
  isMobSidebarOpen,
  setMobIsSidebarOpen,
}) => {
  return (
    <aside
      className={`sidebar ${isWebSidebarOpen ? 'sidebar__open' : ''} ${isMobSidebarOpen ? 'sidebar-mob__open' : ''}`}
    >
      <div className='sidebar-header'>
        <Logo />

        <div
          className='toggle-sidebar-btn'
          role='button'
          tabIndex={0}
          aria-label='toggle-sidebar'
          onClick={() => setWebIsSidebarOpen(!isWebSidebarOpen)}
          onKeyDown={e => {
            if (e.key === 'Enter' || e.key === ' ') {
              setWebIsSidebarOpen(!isWebSidebarOpen);
            }
          }}
        >
          {isWebSidebarOpen ? <ChevronLeft /> : <ChevronRight />}
        </div>
      </div>

      <Nav setMobIsSidebarOpen={setMobIsSidebarOpen} />
    </aside>
  );
};

export default Sidebar;
