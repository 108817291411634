import { useLocation } from 'react-router-dom';

import Download from '@mui/icons-material/Download';
import { useTableContext } from '@shared/components/Table';

import { useBulkActions } from '../hooks/useBulkActions';

export function OrdersDownloadBtn<T>() {
  const { currentFilter } = useTableContext<T>();
  const { handleAction, isDownloadLoading } = useBulkActions(currentFilter);

  const location = useLocation();
  const pathname = location.pathname.replaceAll('/', '-');
  return (
    <button
      className='orders-download-btn'
      onClick={() => handleAction(`download${pathname}`)}
      disabled={isDownloadLoading}
    >
      <Download />
      Download
    </button>
  );
}
