import { PropsWithChildren } from 'react';

import { TableColumnsVisibility } from '@features/table-columns-visibility';
import { FilterAlt } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useModal } from '@shared/components/Modal';
import { useTableContext } from '@shared/components/Table';

import { ResetFilterBtn } from './ResetFilterBtn';

interface IProps extends PropsWithChildren {
  showVisibilityBtn?: boolean;
  showResetBtn?: boolean;
  storageKey?: string;
}

export const FilterControlBlock = <T,>({
  showVisibilityBtn = true,
  showResetBtn = true,
  storageKey,
  children,
}: IProps) => {
  const tableContext = useTableContext<T>();
  const { openModal } = useModal();

  const handleOpenModal = () => {
    openModal(<TableColumnsVisibility storageKey={storageKey} />, 'md', tableContext);
  };

  return (
    <div className='control-block'>
      {showResetBtn && <ResetFilterBtn />}
      {showVisibilityBtn && (
        <Tooltip title='Column visibility'>
          <IconButton type='button' size='small' color='primary' onClick={handleOpenModal}>
            <FilterAlt />
          </IconButton>
        </Tooltip>
      )}
      {children}
    </div>
  );
};
