import { forwardRef } from 'react';

import { Box, Card, Typography } from '@mui/material';

import { ToggleIcon } from './ToggleIcon';

type IProps = {
  title: string;
  isOpen: boolean;
  handleClick: () => void;
  disabled?: boolean;
};

export const Toggle = forwardRef<HTMLDivElement, IProps>(
  ({ title, isOpen, handleClick, disabled }, ref) => {
    const handleToggleCollapse = () => {
      if (disabled) return;
      handleClick();
    };
    return (
      <Card
        elevation={disabled ? 0 : 1}
        ref={ref}
        onClick={handleToggleCollapse}
        sx={{
          my: 2,
          userSelect: disabled ? 'none' : 'auto',
          cursor: disabled ? 'not-allowed' : 'pointer',
          transition: 'all 0.2s',
          '&:hover': {
            bgcolor: disabled ? 'action.disabled' : 'action.hover',
          },
          bgcolor: disabled ? 'action.disabled' : 'white',
          opacity: disabled ? 0.7 : 1,
          px: 2,
          py: 1.5,
        }}
      >
        <Box display='flex' alignItems='center'>
          <Box component='span' sx={{ mr: 1, display: 'flex', alignItems: 'center' }}>
            <ToggleIcon isOpen={isOpen} />
          </Box>
          <Typography variant='body1' sx={{}}>
            {disabled ? `no data for ${title}` : title}
          </Typography>
        </Box>
      </Card>
    );
  },
);

Toggle.displayName = 'Toggle';
