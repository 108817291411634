import { ReactNode } from 'react';

import {
  TTransaction,
  TTransactionOrigin,
  TTransactionType,
  TTransactionTableColumns,
} from '@entities/transactions';
import { ITransactionDepositTableColumns } from '@entities/transactions/deposit';
import { ITransactionWithdrawalTableColumns } from '@entities/transactions/withdrawal';
import ReduceAndCopyString from '@features/reduce-and-copy-string';
import {
  FilterControlBlock,
  FilterDateRange,
  FilterInput,
  FilterSelect,
} from '@features/table-filter';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Tooltip } from '@mui/material';
import RequestStatusLabel from '@shared/components/RequestStatusLabel';
import { formatAmount } from '@shared/utils/formatAmount';

interface IProps {
  type: TTransactionType;
  refetch?: () => void;
}

export const useTableSettings = ({ type, refetch }: IProps) => {
  // THead settings
  const depositColumnTitles: ITransactionDepositTableColumns = {
    id: 'Transaction ID',
    order_id: 'Order ID',
    timestamp: 'Transaction Date',
    wallet: 'Wallet',
    bank_name: 'Bank Name',
    utr: 'UTR',
    amount: 'Amount',
    status: 'Status from bank',
    external_reference: 'External Reference',
    customer_name: 'Customer Name',
    customer_phone: 'Mobile',
    bank_balance: 'Balance',
    origin: 'Origin',
    matched: 'Matched',
    human_hash: 'Short ID',
    upi_id: 'UPI ID',
    created_at: 'Upload Date',
  };

  const withdrawalColumnTitles: ITransactionWithdrawalTableColumns = {
    id: 'Transaction ID',
    order_id: 'Order ID',
    timestamp: 'Transaction Date',
    wallet: 'Wallet',
    bank_name: 'Bank Name',
    utr: 'UTR',
    amount: 'Amount',
    status: 'Status from bank',
    external_reference: 'External Reference',
    customer_name: 'Customer Name',
    customer_phone: 'Mobile',
    bank_balance: 'Balance',
    origin: 'Origin',
    matched: 'Matched',
    human_hash: 'Short ID',
    upi_id: 'UPI ID',
    created_at: 'Upload Date',
  };

  const getColumnTitles = (type: TTransactionType) => {
    switch (type) {
      case 'deposit':
        return depositColumnTitles;
      case 'withdrawal':
        return withdrawalColumnTitles;
    }
  };

  const filterStatuses = ['pending', 'new', 'success', 'failed'];
  const filterStatusesOptions = filterStatuses.map(status => ({
    id: status,
    label: status,
  }));

  const filterMatchedOptions = [
    { id: 'true', label: 'Yes' },
    { id: 'false', label: 'No' },
  ];

  const filterOrigins: TTransactionOrigin[] = ['sms', 'parser', 'upload'];
  const filterOriginsOptions = filterOrigins.map(origin => ({
    id: origin,
    label: origin,
  }));

  const renderFilterElement = (key: keyof TTransaction) => {
    switch (key) {
      case 'status':
        return <FilterSelect name={key} options={filterStatusesOptions} />;
      case 'matched':
        return <FilterSelect name={key} options={filterMatchedOptions} />;
      case 'origin':
        return <FilterSelect name={key} options={filterOriginsOptions} />;
      case 'created_at':
      case 'timestamp':
        return <FilterDateRange name={key} />;

      default:
        return <FilterInput name={key} />;
    }
  };

  const getTheadBefore = (): ReactNode => {
    return null;
  };
  const getTheadAfter = () => (
    <th>
      <FilterControlBlock storageKey={`transaction-${type}`}>
        <Tooltip title='Refresh'>
          <button className='table-control-menu-icon-button' title='Refresh' onClick={refetch}>
            <RefreshIcon color='primary' />
          </button>
        </Tooltip>
      </FilterControlBlock>
    </th>
  );

  // TBody settings
  const renderRow = (key: keyof TTransactionTableColumns, transaction: TTransaction) => {
    switch (key) {
      case 'amount':
        return (
          transaction[key] && `${formatAmount(String(transaction[key]))} ${transaction.currency}`
        );
      case 'matched':
        return transaction[key] ? 'Yes' : 'No';
      case 'utr':
        return <ReduceAndCopyString text={transaction[key]} />;
      case 'human_hash':
        return <ReduceAndCopyString text={transaction.payload.human_hash} />;
      case 'status':
        return <RequestStatusLabel status={String(transaction[key])} />;
      case 'customer_phone':
        return transaction.payload.customer_phone;
      default:
        return transaction[key];
    }
  };

  const getTbodyBefore = (transaction: TTransaction): ReactNode => {
    return null;
  };
  const getTbodyAfter = (transaction: TTransaction): ReactNode => {
    return <td></td>;
  };

  return {
    columnTitles: getColumnTitles(type),

    renderFilterElement,
    getTheadBefore,
    getTheadAfter,

    renderRow,
    getTbodyBefore,
    getTbodyAfter,
  };
};
