import { FC } from 'react';

import Skeleton from 'react-loading-skeleton';
import Select from 'react-select';

import { IBank, useBanks } from '@entities/bank';
import ErrorMessage from '@shared/components/ErrorMessage';
import { reactSelectStyles } from '@shared/components/Form';

import 'react-loading-skeleton/dist/skeleton.css';

interface IProps {
  onChangeBank?: (bankId: string) => void;
}

export const BanksSelect: FC<IProps> = ({ onChangeBank }) => {
  const { banks, isLoading, isFetching, error } = useBanks({
    disableUrlFilter: true,
  });

  if (isLoading) return <Skeleton height={42} />;
  if (error) {
    return (
      <>
        <select></select>
        <ErrorMessage error={error} />
      </>
    );
  }

  if (banks) {
    return (
      <Select
        styles={reactSelectStyles<IBank, false>()}
        name='bank_id'
        maxMenuHeight={200}
        placeholder='Select bank'
        options={banks.data}
        getOptionValue={bank => bank.id}
        getOptionLabel={bank => bank.name}
        onChange={(newValue: IBank, actionMenta) => {
          if (actionMenta.action === 'select-option') {
            onChangeBank?.(newValue.id);
          }
        }}
        isDisabled={isFetching}
      />
    );
  }

  return null;
};
