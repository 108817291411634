import { FC } from 'react';

import { TRequestType, useViewRequestQuery } from '@entities/requests';
import { JsonSchema, RequestPrettyView } from '@shared/components/DataTableView';
import LoadingSpinner from '@shared/components/LoadingSpinner';

interface IProps {
  type: TRequestType;
  id: string;
}

export const ViewRequest: FC<IProps> = ({ id, type }) => {
  const { data: requestInfo, isLoading } = useViewRequestQuery({ id });

  // TODO: Implement in the second phase
  // const transformValue = (value: IObjectDeep[keyof IObjectDeep]): JSX.Element => {
  //   if (typeof value === 'string' && value.includes('ParserTask:')) {
  //     const [message, taskId] = value.split('ParserTask:');

  //     return (
  //       <>
  //         {message} ParserTask: &nbsp;
  //         <button
  //           className='link-styled-text'
  //           onClick={() => openModal(<ViewParserTask id={taskId.trim()} type={type} />, 'lg')}
  //         >
  //           {taskId.trim()}
  //         </button>
  //       </>
  //     );
  //   }

  //   return <>{value}</>;
  // };

  if (isLoading) return <LoadingSpinner />;

  if (requestInfo) {
    return (
      <div className='request-info-container'>
        <RequestPrettyView data={requestInfo.data as unknown as JsonSchema} />
      </div>
    );
  }

  return null;
};
