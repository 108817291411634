import { baseApi } from '@app/store/index.api';

import { IRequestWithdrawalResponse } from '../model/types';

const requestWithdrawalApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    requestWithdrawalFilter: builder.query<IRequestWithdrawalResponse, string>({
      query: query => {
        return {
          url: `/requests/withdrawals/filter?${query}`,
        };
      },
    }),
    requestWithdrawal: builder.query<IRequestWithdrawalResponse, string>({
      query: query => {
        return {
          url: `/requests/withdrawals?${query}`,
        };
      },
    }),
  }),
});

export const { useRequestWithdrawalQuery, useLazyRequestWithdrawalFilterQuery } =
  requestWithdrawalApi;
