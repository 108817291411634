import Send from '@mui/icons-material/Send';
import { useTableContext } from '@shared/components/Table';

import { useBulkActions } from '../hooks/useBulkActions';

export function OrdersResendBtn<T>() {
  const { currentFilter } = useTableContext<T>();
  const { handleAction, isResendCallbackLoading } = useBulkActions(currentFilter);

  return (
    <button
      className='orders-resend-icon-btn'
      onClick={() => {
        handleAction(`resend-callback`);
      }}
      disabled={isResendCallbackLoading}
    >
      <Send />
      Resend
    </button>
  );
}
