import { FC } from 'react';

import { TWalletType, useWallets } from '@entities/wallets';
import type { IWalletTableColumns } from '@entities/wallets';
import { useRefetchAuto } from '@features/refetch-auto';
import ErrorMessage from '@shared/components/ErrorMessage';
import Loading from '@shared/components/Loading';
import LoadingSpinner from '@shared/components/LoadingSpinner';
import { Pagination, PaginationRows } from '@shared/components/Pagination';
import { Table, THead, TBody, TableContainer, TableProvider } from '@shared/components/Table';

import { useTableSettings } from './settings/useTableSettings';

import './monitoring.css';

interface IProps {
  type: TWalletType;
}

const WalletsWidget: FC<IProps> = ({ type }) => {
  const { refreshInterval, setRefreshInterval } = useRefetchAuto({
    storageKey: `refetch-auto-wallet-${type}`,
  });

  const {
    wallets,
    isLoading,
    isError,
    error,
    isFetching,
    refetch,

    filter,
  } = useWallets({ type, refreshInterval });

  const { columnTitles, renderFilterElement, renderRow, renderItem } = useTableSettings({
    type,
    refetch,
    refreshInterval,
    setRefreshInterval,
  });

  if (isLoading) return <LoadingSpinner />;
  if (isError) return <ErrorMessage error={error} />;

  if (wallets) {
    return (
      <>
        {isFetching && <Loading />}

        <TableProvider<IWalletTableColumns> columnTitles={columnTitles} {...filter}>
          <TableContainer className='wallets-table table-docked-controls'>
            <Table>
              <THead renderItem={renderFilterElement} />
              <TBody items={wallets.data} renderItem={renderItem} renderRow={renderRow} />
            </Table>
          </TableContainer>
          <div className='pagination-container'>
            <Pagination data={wallets.meta} />
            <PaginationRows />
          </div>
        </TableProvider>
      </>
    );
  }

  return null;
};

export default WalletsWidget;
