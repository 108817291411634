import { ReactNode } from 'react';

import { FormItem } from '@shared/components/Form';
import { IBaseObject, TObject } from '@shared/types/fetch-data';
import { getTypedObjectEntries } from '@shared/types/typedObjectEntries';
import { isObject } from '@shared/utils/isObject';
import { transformObjectKeyToTitle } from '@shared/utils/transformObjectKeyToTitle';

interface IProps<T> {
  entity: T;
  title: string;
  renamedLabels?: { [K in keyof T | string]?: string };
  deepEntityValues?: { [K in keyof T]?: string[] };
  excludeKeys?: (keyof T)[];
  additionalData?: { label: string; element: ReactNode }[];
}

export const ModalViewEntity = <T extends IBaseObject>({
  entity,
  title,
  renamedLabels,
  deepEntityValues,
  excludeKeys = [],
  additionalData,
}: IProps<T>) => {
  const renderItem = (key: keyof T | string, value: T[keyof T], labelPrefix?: string) => {
    const keyString = String(key);
    const valueString = String(value);
    const label = labelPrefix
      ? labelPrefix
      : renamedLabels?.[key] || `${transformObjectKeyToTitle(keyString)}:`;

    switch (key) {
      case 'description':
        return (
          <FormItem key={keyString} name={keyString} label={label}>
            <textarea name={keyString} id={keyString} readOnly defaultValue={valueString} />
          </FormItem>
        );

      case 'logo_url':
        return (
          <FormItem key={keyString} name={keyString} label={label}>
            <div className='form-field-pic'>
              <img src={valueString} alt={valueString} />
            </div>
          </FormItem>
        );

      default:
        return (
          <FormItem key={keyString} name={keyString} label={label}>
            <input
              type='text'
              name={keyString}
              id={keyString}
              defaultValue={value && valueString}
              readOnly
            />
          </FormItem>
        );
    }
  };

  const transformDeepKeyToTitle = (key: string, deepKey: string) => {
    return `${transformObjectKeyToTitle(key)} ${transformObjectKeyToTitle(deepKey)}:`;
  };

  return (
    <>
      <h2>{title}</h2>
      <form className='popup-form'>
        {getTypedObjectEntries(entity)
          .filter(([key]) => !excludeKeys.includes(key))
          .map(([key, value]) => {
            if (isObject(value) && !deepEntityValues?.[key]) {
              return null;
            }

            if (isObject(value) && deepEntityValues?.[key]) {
              const deepKeys = deepEntityValues[key];

              return deepKeys.map(deepKey => {
                const deepValue = (value as TObject)[deepKey];
                const deepLabel = transformDeepKeyToTitle(String(key), deepKey);

                return renderItem(deepKey, deepValue, deepLabel);
              });
            }

            return renderItem(key, value);
          })}

        {additionalData &&
          additionalData.map(({ label, element }) => {
            return (
              <FormItem key={label} name={label} label={label}>
                {element}
              </FormItem>
            );
          })}
      </form>
    </>
  );
};
