import { useState } from 'react';

import { FormItemWithError } from '@shared/components/Form';
import { useModal } from '@shared/components/Modal';
import { TUseMutation } from '@shared/types/fetch-data';

import { IRequestModalContextProps } from '../model/types';

interface IProps<TQuery, TResponse> {
  data: TQuery;
  message: string;
  mutation: TUseMutation<TQuery, TResponse>;
  mutationErrorName: string;
  refetch: () => void;
}

export const ConfirmApproveRefundRequest = <Q, Res>({
  data,
  message,
  mutation,
  mutationErrorName,
  refetch,
}: IProps<Q, Res>) => {
  const [changeStatus, { isLoading, isError, error }] = mutation();

  const [reason, setReason] = useState('');

  const { closeModal } = useModal<IRequestModalContextProps>();

  const handleChangeStatusRequest = async () => {
    try {
      const result = await changeStatus({ ...data, reason });

      if ('data' in result) {
        closeModal();
        refetch();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='modal-confirm'>
      <div className='modal-confirm__title'>Are you sure?</div>
      <div className='modal-confirm__message'>{message}</div>

      <FormItemWithError
        name='reason'
        secondName={mutationErrorName}
        label='Reason'
        isError={isError}
        error={error}
      >
        <input
          type='text'
          name='reason'
          id='reason'
          value={reason}
          onChange={e => setReason(e.target.value)}
        />
      </FormItemWithError>

      <div className='modal-confirm__btns'>
        <button disabled={isLoading} className='btn-confirm' onClick={handleChangeStatusRequest}>
          Yes
        </button>
        <button className='btn-cancel secondary-btn' onClick={closeModal}>
          No
        </button>
      </div>
    </div>
  );
};
