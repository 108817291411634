import './loading.css';

interface IProps {
  type?: 'default' | 'indeterminate';
}

const Loading = ({ type = 'default' }: IProps) => (
  <div className={`loading-container loading-${type}`}>
    <div className='loading'>
      <div className='loading-progress'></div>
    </div>
  </div>
);

export default Loading;
