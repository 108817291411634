import { ReactJsonViewProps } from '@microlink/react-json-view';
import { Typography } from '@mui/material';

import { Highlight } from './Highlight';
import { ViewConfig } from './types';

// Common props for all json views
export const commonJsonViewProps: Partial<ReactJsonViewProps> = {
  displayArrayKey: false,
  enableClipboard: true,
  quotesOnKeys: false,
  displayDataTypes: false,
};

// Main driver of the view, defines the cards and blocks to be rendered
export const defaultViewConfig: ViewConfig = {
  singleCards: [
    {
      key: 'request',
      promotedKeys: ['status', 'status_reason'],
      dataPath: '.',
      unpackPromotedKeys: true,
      headerRenderer: item => (
        <Typography>
          Request <Highlight canCopyClipboard>{item.id.toString()}</Highlight>
        </Typography>
      ),
    },
    {
      key: 'request_log',
      dataPath: 'request_log',
      headerRenderer: item => (
        <Typography>
          Request log <Highlight canCopyClipboard>{item.id.toString()}</Highlight>
        </Typography>
      ),
    },
    {
      key: 'order',
      dataPath: 'order',
      headerRenderer: item => (
        <Typography>
          Order <Highlight canCopyClipboard>{item.id.toString()}</Highlight>
        </Typography>
      ),
    },
    {
      key: 'transaction',
      dataPath: 'transaction',
      promotedKeys: ['status', 'payload'],
      promotedKeyAsRoot: 'payload',
      headerRenderer: item => (
        <Typography>
          Transaction <Highlight>{item.id.toString()}</Highlight>
        </Typography>
      ),
    },
  ],
  blockCards: [
    {
      key: 'audits',
      title: 'Audits',
      dataPath: '.',
      headerRenderer: item => (
        <Typography>
          event: <Highlight>{item.event.toString()}</Highlight>
        </Typography>
      ),
      collections: [
        { path: 'audits', icon: '', name: 'Request' },
        { path: 'order.audits', icon: '', name: 'Order' },
        { path: 'transaction.audits', icon: '', name: 'Transaction' },
      ],
    },
    {
      key: 'stored_events',
      title: 'Stored Events',
      promotedKeys: ['event_properties', 'meta_data'],
      dataPath: '.',
      headerRenderer: item => (
        <Typography>
          event_class: <Highlight>{item.event_class.toString().split('\\').pop()}</Highlight>
        </Typography>
      ),
      collections: [
        { path: 'stored_events', icon: '', name: 'Request' },
        { path: 'order.stored_events', icon: '', name: 'Order' },
        { path: 'transaction.stored_events', icon: '', name: 'Transaction' },
      ],
    },
    {
      key: 'decisions',
      title: 'Decisions',
      dataPath: '.',
      withCollectionSelector: false,
      headerRenderer: item => (
        <Typography>
          status:{' '}
          <Highlight>
            {item.status_from.toString()} → {item.status_to.toString()}
          </Highlight>
        </Typography>
      ),
      collections: [{ path: 'decisions', icon: '', name: 'Request' }],
    },
  ],
};
